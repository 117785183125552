import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import logo from "../images/logo.png";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginBottom: theme.spacing(3),
        textAlign: "center",
        zIndex: 99999,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        fontSize: "30px"
    },
}));

export default function CustomAppBar() {
    const classes = useStyles();

    return (
        <div style={{ marginBottom: 100 }}>
            <AppBar position="fixed" className={classes.root}>
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <img src={logo} height={64} alt="logo" />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Wallet Payment Gateway
                    </Typography>
                </Toolbar>
            </AppBar>
        </div>
    );
}