import React, { } from "react";
import "toastr/build/toastr.css";
import { Alert, AlertTitle } from '@material-ui/lab';
import jwt_decode from "jwt-decode";
import TronPayment from "./coins/trx";
import BTCPayment from "./coins/btc";
import BNBPayment from "./coins/bnb";
import TronUSDTPayment from "./coins/tusdt";
import BUSDTPayment from "./coins/busdt";
var currency = "";
let orgAmount = 0;
let tokenAddress = "";
require("dotenv").config();

function Dashboard() {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenInfo = urlParams.get('token');
    const dataInfo = urlParams.get('data');
    try {
        let transInfo = jwt_decode(tokenInfo);
        let curAddress = jwt_decode(dataInfo);
        currency = transInfo.type;
        tokenAddress = transInfo.tokenAddress;
        orgAmount = currency.toLowerCase() === 'trx' ? parseFloat(curAddress.coins).toFixed(6) : curAddress.coins;
    }
    catch (e) {
        console.log(e);
    }


    if (orgAmount === 0) {
        return <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            You are Not Authorised to View this Page — <strong>check it out!</strong>
        </Alert>;
    }

    if (currency.toLowerCase() === "trx") {
        if (tokenAddress) {
            return <TronUSDTPayment tokenInfo={tokenInfo} dataInfo={dataInfo} />
        }
        else {
            return <TronPayment tokenInfo={tokenInfo} dataInfo={dataInfo} />
        }
    }
    else if (currency.toLowerCase() === "btc") {
        return <BTCPayment tokenInfo={tokenInfo} dataInfo={dataInfo} />
    }
    else if (currency.toLowerCase() === "bnb") {
        return <BNBPayment tokenInfo={tokenInfo} dataInfo={dataInfo} />
    }
    else if (currency.toLowerCase() === "usdt") {
        return <TronUSDTPayment tokenInfo={tokenInfo} dataInfo={dataInfo} />
    }
    else if (currency.toLowerCase() === "busd") {
        return <BUSDTPayment tokenInfo={tokenInfo} dataInfo={dataInfo} />
    }
    else {
        return <p>Not Supported</p>
    }
}

export default Dashboard;
