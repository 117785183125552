import { Button, Card, CardContent, CardHeader, Grid, InputAdornment, Snackbar, TextField, Typography } from "@material-ui/core";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Alert, AlertTitle } from '@material-ui/lab';
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { QRCode } from 'react-qrcode-logo';
import toastr from "toastr";
import "toastr/build/toastr.css";
import Timer from "../timer";
var sourceAddress = "";
var currency = "";
var successUrl = "";
var failureUrl = "";
let orgAmount = 0;
let transInfo = {};
var ackId = '';
var transactionCharge = 0;
var token = "b9dcc9f060cf4c35975f120c6fa51292";
require("dotenv").config();

export default function BTCPayment(props) {
    const { tokenInfo, dataInfo } = props;
    let seconds = 0;
    try {
        transInfo = jwt_decode(tokenInfo);
        let curAddress = jwt_decode(dataInfo);
        sourceAddress = curAddress.address;
        successUrl = transInfo.successUrl;
        failureUrl = transInfo.failureUrl;
        currency = transInfo.type;
        transactionCharge = curAddress.transactionCharge;
        orgAmount = curAddress.coins;
        ackId = curAddress.ackId;
        const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
        const endTime = transInfo.exp; // use UNIX timestamp in seconds
        seconds = endTime - stratTime;
    }
    catch (e) {
        console.log(e);
    }
    const [transactionId, setTransactionId] = useState("Waiting For Payment");
    const [status, setStatus] = useState("Waiting For Payment");
    const [amount, setAmount] = useState(orgAmount);
    const [timeLeft, setTimeLeft] = useState(seconds);
    const [curScriptId, setScriptId] = useState("Waiting For Payment");

    function pinger(ws) {
        var timer = setInterval(function () {
            if (ws.readyState == 1) {
                ws.send(JSON.stringify({ event: "ping" }));
            }
        }, 5000);
        return { stop: function () { clearInterval(timer); } };
    }

    useEffect(() => {
        if (orgAmount !== 0) {
            var ws = new WebSocket("wss://socket.blockcypher.com/v1/btc/main?token=" + token);
            var ping = pinger(ws);

            ws.onmessage = function (event) {
                let curData = JSON.parse(event.data);
                log(event.data);
                if (curData.confirmations <= 0) {
                    setTimeLeft(120);
                    // setTransactionId(curData.hash);
                    let scriptId = curData.outputs.filter((x) => x.addresses.indexOf(sourceAddress) > -1);
                    if (scriptId.length > 0) {
                        setAmount(scriptId[0].value);
                        setScriptId(scriptId[0].script);
                        setTransactionId(curData.hash);
                        toastr.success("Transaction Initiated Waiting for the Block Chain to Confirm");
                        setStatus("Transaction Initiated Waiting for the Block Chain to Confirm");
                        fetch(process.env.REACT_APP_API_URL + ackId + '?hash=' + curData.hash, {
                            method: 'PUT',
                            headers: {
                                'Authorization': 'Bearer ' + tokenInfo
                            }
                        }).then((x) => {
                            ping.stop();
                            ws.close();
                            document.location.href = successUrl;
                        }).catch((err) => {
                            toastr.error("We received your payment.but we are unabe process request now", "Update Status");
                            console.log(err);
                            setTimeout(() => {
                                document.location.href = failureUrl;
                            }, 5000);
                        });
                    }
                }
            }

            ws.onopen = function (event) {
                ws.send(JSON.stringify({ event: "tx-confirmation", address: sourceAddress }));
            }

        }
    }, []);


    if (transactionId === "Waiting For Payment" && timeLeft <= 0) {
        return <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Session Expired — <strong>check it out!</strong>
        </Alert>;
    }

    if (transactionId !== "Waiting For Payment") {
        return <>
            <Alert severity="success">
                <AlertTitle>Payment Success</AlertTitle>
                Your Transaction is Confirmed with transaction hash&nbsp;&nbsp;&nbsp;
                <a style={{ color: "white", wordBreak: "break-word" }}
                    href={"https://www.blockchain.com/btc/tx/" + transactionId}>{transactionId}</a>
                <br /><strong>For more information&nbsp;&nbsp;&nbsp;
                    <a style={{ color: "white" }}
                        href={"https://www.blockchain.com/btc/tx/" + transactionId}>Click Here</a>
                </strong>
            </Alert>
            <Alert severity="error">
                <strong>Please dont click back or close the browser</strong>
            </Alert>
        </>;
    }


    function log(msg) {
        console.log(msg);
        var node = document.createElement("LI");
        if (node) {             // Create a <li> node
            var textnode = document.createTextNode(msg);
            if (node && node.appendChild) {    // Create a text node
                node.appendChild(textnode);
            }
            if (document.getElementById("log")) {
                document.getElementById("log").appendChild(node);
            }
        }
    }

    function copyText() {
        var copyText = document.getElementById("myInput");

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        document.execCommand("copy");
        toastr.success("Link Copied to Clipboard");
    }

    return (
        <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12} style={{ "textAlign": "center" }}>
                <Card elevation={10}>
                    <CardHeader title={"Coins : " + amount + " " + currency}
                        subheader={"Transaction Status : " + transactionId} />
                    <CardContent>


                        {
                            timeLeft > 300 ?
                                <>
                                    <QRCode value={sourceAddress} />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={sourceAddress}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FileCopyIcon htmlColor="#b27919" onClick={() => copyText()} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        inputProps={{
                                            "aria-readonly": true,
                                            id: "myInput"
                                        }} />
                                    <br />
                                    <br />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={amount}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    BTC
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FileCopyIcon htmlColor="#b27919" onClick={() => copyText("amtInput")} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        inputProps={{
                                            "aria-readonly": true,
                                            id: "amtInput"
                                        }} />
                                    <Snackbar open={true}>
                                        <Alert severity="warning">
                                            Please pay exactly {amount} BTC. Else your transaction will be errored out.
                                        </Alert>
                                    </Snackbar>
                                    <Typography variant="h4" component="p">
                                        Or
                                    </Typography>
                                    <br />
                                    <div>
                                        <a
                                            target="_blank"
                                            className="btn"
                                            href={`https://link.trustwallet.com/send?coin=0&address=${sourceAddress}&amount=${amount}`}>
                                            Pay Using Trust Wallet
                                        </a>
                                    </div>
                                </> :
                                <>
                                    <Alert severity="warning" style={{ textAlign: "left" }}>
                                        <AlertTitle>Transaction Status</AlertTitle>
                                        If Payment is already done by you, <strong>Please wait for some time for confirmations.</strong>
                                    </Alert>
                                    <br />
                                    <br />
                                    <Alert severity="error" style={{ textAlign: "left" }}>
                                        <AlertTitle>Skip Payment</AlertTitle>
                                        <a href={failureUrl}>Click Here</a><strong> to leave the page without payment.</strong>
                                    </Alert>
                                </>
                        }

                        {
                            status === "Transaction Confirmed By Block Chain" ?
                                <Alert severity="success">
                                    <AlertTitle>Success</AlertTitle>
                                    Transaction Confirmed — <strong>check it out!</strong>
                                </Alert>
                                :
                                <div>
                                    <br />
                                    <br />
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        startIcon={<AutorenewIcon />}
                                    >
                                        {status}
                                    </Button>

                                </div>
                        }

                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
                <Card elevation={10}>
                    <CardContent>
                        <ul className="details">
                            <li><label>Name: </label><b>{transInfo.name}</b></li>
                            <li><label>Deposit Amount:  </label><b>{(parseFloat(orgAmount) - parseFloat(transactionCharge)).toFixed(8)} {currency.toUpperCase()}</b></li>
                            <li><label>Transaction Charge :  </label><b>{parseFloat(transactionCharge).toFixed(8)} {currency.toUpperCase()}</b></li>
                            <li><label>Customer ID:  </label><b>{transInfo.cid}</b></li>
                            <li><label>Email:  </label><b>{transInfo.email}</b></li>
                            <li><label>Mobile No: </label><b>{transInfo.mobile}</b></li>
                            <li><label>Reference No: </label><b>{transInfo.txnid}</b></li>
                        </ul>
                        {
                            status === "Waiting For Payment" ?
                                <Timer duration={seconds} onComplete={(duration) => {
                                    setTimeLeft(duration);
                                }} /> : <span />
                        }
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <ul id="log">
                </ul>
            </Grid>
        </Grid>
    );
}

