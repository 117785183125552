import { createMuiTheme } from '@material-ui/core/styles';

export default (createMuiTheme)({
    typography: {
        fontFamily: [
            '"Montserrat"',
            'sans-serif'
        ].join(','),
        fontWeightBold: 800,
        fontWeightLight: 400,
        fontWeightMedium: 600,
        fontWeightRegular: 500
    },
    MuiInputBase: {
        input: {
            fontFamily: [
                '"Montserrat"',
                'sans-serif'
            ].join(','),
            fontWeightBold: 800,
            fontWeightLight: 400,
            fontWeightMedium: 600,
            fontWeightRegular: 500
        }
    }
});
