import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/messaging';

const app = firebase.initializeApp({
    apiKey: "AIzaSyCCADEDLLNjnfaISEnHBNbFEhZfudazBlU",
    authDomain: "crypto-wallet-payment.firebaseapp.com",
    projectId: "crypto-wallet-payment",
    storageBucket: "crypto-wallet-payment.appspot.com",
    messagingSenderId: "194390069826",
    appId: "1:194390069826:web:5406dcd7e2f8d468a553db"
});

export const firestore = firebase.firestore(app);
export const messaging = firebase.messaging(app);